<template>
    <div class="wrapper">
        <div class="cart-menu" v-if="mealKit" v-click-outside="closeCart">
            <div class="header">
                <h3>{{ $filters.getTranslationByKey(mealKit.name) }}</h3>
                <CRButton class="btn-close" @click="closeCart" close />
            </div>
            <CartSection v-if="!mealKit.isUnique" :title="$t('cart.kitType')">
                <CRRadio
                    class="radio"
                    v-for="menuType in mealKit.menuTypes"
                    :key="menuType.id"
                    :value="menuType"
                    v-model="form.menuType"
                >
                    {{ $t(`common.${menuType.type}`) }}
                </CRRadio>
            </CartSection>
            <CartSection v-if="!mealKit.isUnique" :title="$t('cart.mealSelection')">
                <CRRadio
                    class="radio"
                    v-for="combination in mealKit.combinations"
                    :key="combination.id"
                    :value="combination"
                    v-model="form.combination"
                >
                    {{ $filters.getTranslationByKey(combination.name) }}
                </CRRadio>
            </CartSection>
            <CartSection v-if="!mealKit.isUnique" :title="$t('common.calorieNorm')">
                <CRRadio
                    class="radio"
                    v-for="calorie in mealKit.calories"
                    :key="calorie.id"
                    :value="calorie"
                    v-model="form.calorie"
                >
                    {{ calorie.amount }}
                </CRRadio>
            </CartSection>
            <CartSection v-if="!mealKit.isUnique" :title="$t('cart.term')">
                <p class="tooltip-text">
                    {{ $t('cart.periodsTooltipText') }}
                </p>
                <CRRadio
                    class="radio"
                    v-for="period in mealKit.periods"
                    :key="period.id"
                    :value="period"
                    v-model="form.period"
                >
                    {{ $filters.getTranslationByKey(period.name) }}
                </CRRadio>
            </CartSection>
            <CartSection v-if="!mealKit.isUnique" :title="$t('common.allergens')">
                <p class="tooltip-text">
                    {{ $t('cart.allergensTooltipText') }}
                </p>
                <CRCheckbox
                    class="radio"
                    v-for="allergen in allergens"
                    :key="allergen.id"
                    :value="allergen"
                    :checked="checkIfObjectAddedInArray('allergens', allergen)"
                    @change="handelToggle(allergen, 'allergens')"
                >
                    {{ renderAllergenName(true, allergen) }}
                </CRCheckbox>
                <CRInput v-if="form.showAllergenInput" v-model="form.additionalAllergen" />
            </CartSection>
            <CartSection v-if="!mealKit.isUnique" :title="$t('common.additions')">
                <CRCheckbox
                    class="radio"
                    v-for="addition in additions"
                    :key="addition.id"
                    :value="addition"
                    :checked="checkIfObjectAddedInArray('additions', addition)"
                    @change="handelToggle(addition, 'additions')"
                    is-box
                >
                    <template #content>
                        <div class="addition__inner">
                            <div class="content">
                                <h6 class="name">
                                    {{ $filters.getTranslationByKey(addition.name) }}
                                </h6>
                                <p>{{ $t('cart.forWorkingDays', { amount: form.period.amount }) }}</p>
                                <h6>{{ getAdditionPrice(addition.price, form.period.amount) }}€</h6>
                            </div>
                            <div class="img" :style="backgroundStyles(addition.picture?.picturePath.thumbnail)" />
                        </div>
                    </template>
                </CRCheckbox>
            </CartSection>
            <CartSection :title="$t('cart.numberOfKits')">
                <CRSpin class="spin" v-model="form.amount" :min="1" />
            </CartSection>
            <CartSection class="calc-section">
                <CRButton pattern="thin" class="reset-btn" @click="setInitialState">
                    {{ $t('buttons.resetOptions') }}
                </CRButton>
                <CalcSection
                    :title="`${form.amount} x ${$filters.getTranslationByKey(mealKit.name)}`"
                    :price="mealKitPrice"
                />
                <template v-if="!mealKit.isUnique">
                    <CalcSection
                        v-for="addition in form.additions"
                        :key="addition.id"
                        :title="$filters.getTranslationByKey(addition.name)"
                        :price="getAdditionPrice(addition.price, form.period.amount)"
                        is-secondary
                    />
                    <CalcSection
                        v-for="allergen in form.allergens"
                        :key="allergen.id"
                        :title="$filters.getTranslationByKey(allergen.name)"
                        :price="getAllergenPrice(allergen.percentage, this.mealKitPrice)"
                        is-secondary
                    />
                </template>
                <CalcSection
                    v-if="mealKit.discount"
                    :title="$t('common.discount')"
                    :price="discountAmount"
                    is-secondary
                    is-discount
                />
                <div class="total-calc">
                    <h6>{{ $t('common.total') }}:</h6>
                    <CalcSection
                        v-if="!mealKit.isUnique"
                        :title="$t('cart.forDays', { amount: form.period.amount })"
                        :price="totalPrice"
                        is-reverse
                    />
                    <CalcSection v-else :title="$t('cart.forADay')" :price="totalPrice" is-reverse />
                </div>
                <CRButton @click="addToCart" class="btn" pattern="outline" :disabled="!isAddToCartBtnDisabled">
                    {{ editMode.isOpened ? $t('buttons.update') : $t('buttons.addToCart') }}
                </CRButton>
                <CRButton class="btn" @click="toCheckOutPage">
                    {{ editMode.isOpened ? $t('buttons.updateAndMakeAnOrder') : $t('buttons.makeAnOrder') }}
                </CRButton>
            </CartSection>
        </div>
    </div>
</template>

<script>
    import CRCheckbox from '@/components/Common/Checkbox/CRCheckbox.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CalcSection from '@/components/Cart/CalcSection.vue';
    import CRInput from '@/components/Common/Inputs/CRInput.vue';
    import CRRadio from '@/components/Common/Radio/CRRadio.vue';
    import CartSection from '@/components/Cart/CartSection.vue';
    import CRSpin from '@/components/Common/Inputs/CRSpin.vue';
    import vClickOutside from 'click-outside-vue3';
    import { mapActions, mapGetters } from 'vuex';
    import { backgroundStyles } from '@/helpers/CssHelper';
    import { cartMixin, calculateMealKitPiceMixin } from '@/utils/mixins';
    import { getAllergenPrice, getMealKitAllPrices } from '@/helpers/CartHelper';
    import { ConversionEventNameEnum, GoogleAnalyticsEnum } from '@/utils/enums';

    export default {
        name: 'CartSideMenu',
        components: { CartSection, CRButton, CRRadio, CRCheckbox, CRInput, CRSpin, CalcSection },
        directives: {
            clickOutside: vClickOutside.directive,
        },
        mixins: [cartMixin, calculateMealKitPiceMixin],
        data() {
            return {
                backgroundStyles,
                getAllergenPrice,
            };
        },
        computed: {
            ...mapGetters('cart', ['editMode']),

            discountAmount() {
                return getMealKitAllPrices(this.form).discountAmount;
            },

            mealKitPrice() {
                return getMealKitAllPrices(this.form).mealKitPrice;
            },

            isAddToCartBtnDisabled() {
                return !!(
                    (this.form.showAllergenInput && this.form.additionalAllergen) ||
                    (!this.form.showAllergenInput && !this.form.additionalAllergen)
                );
            },
        },
        watch: {
            'form.allergens'(value) {
                fbq('track', ConversionEventNameEnum.CUSTOMIZE_PRODUCT, {
                    content_name: 'allergens',
                    contents: value,
                });
            },
            'form.additions'(value) {
                fbq('track', ConversionEventNameEnum.CUSTOMIZE_PRODUCT, {
                    content_name: 'additions',
                    contents: value,
                });
            },
            'form.mealKit'(value) {
                fbq('track', ConversionEventNameEnum.CUSTOMIZE_PRODUCT, {
                    content_name: 'mealKit',
                    contents: value,
                });
            },
            'form.calorie'(value) {
                fbq('track', ConversionEventNameEnum.CUSTOMIZE_PRODUCT, {
                    content_name: 'Calories',
                    contents: value,
                });
            },
            'form.menuType'(value) {
                fbq('track', ConversionEventNameEnum.CUSTOMIZE_PRODUCT, {
                    content_name: 'MenuType',
                    contents: value,
                });
            },
            'form.combination'(value) {
                fbq('track', ConversionEventNameEnum.CUSTOMIZE_PRODUCT, {
                    content_name: 'Combination',
                    contents: value,
                });
            },
            'form.period'(value) {
                fbq('track', ConversionEventNameEnum.CUSTOMIZE_PRODUCT, {
                    content_name: 'Period',
                    contents: value,
                });
            },

        },
        methods: {
            ...mapActions('cart', ['toggleCart', 'addMealKitInCart', 'updateMealKitInCart', 'toggleEditMode']),

            async toCheckOutPage() {
                this.addToCart();

                this.$router.push({ name: 'checkOut' });

                fbq('track', ConversionEventNameEnum.INITIATE_CHECK_OUT);

            },

            async addToCart() {
                if (this.editMode.isOpened) {
                    this.updateMealKitInCart(this.form);
                } else {
                    this.addMealKitInCart(this.form);
                }

                this.closeCart();

                this.$gtag.event(GoogleAnalyticsEnum.ADD_TO_CART, {
                    currency: 'EUR',
                    value: this.form.totalPrice,
                    items: [this.form.mealKit],
                });

                fbq('track', ConversionEventNameEnum.ADD_TO_CART, {
                    currency: 'EUR',
                    content_ids: [this.form.mealKit.id],
                    content_name: this.form.mealKit.name,
                    content_type: 'product',
                });
            },

            closeCart() {
                if (!this.editMode.isOpened) {
                    this.toggleCart({ isOpened: false, mealKit: null });
                } else {
                    this.toggleEditMode({
                        editMode: {
                            isOpened: false,
                            cartItemId: null,
                        },
                        isOpened: false,
                        mealKit: null,
                    });
                }
            },

            handelToggle(item, field) {
                if (this.checkIfObjectAddedInArray(field, item)) {
                    this.form[field] = this.form[field].filter((elem) => elem.id !== item.id);

                      
                    fbq('track', ConversionEventNameEnum.CUSTOMIZE_PRODUCT, {
                        content_name: item.name,
                        content_category: field,
                    });

                    this.toggleAllergenInput(field, item.isSpecific, false);
                } else {
                    this.form[field].push(item);
                    
                    fbq('track', ConversionEventNameEnum.CUSTOMIZE_PRODUCT, {
                        content_name: item.name,
                        content_category: field,
                    });

                    this.toggleAllergenInput(field, item.isSpecific, true);
                }
            },

            toggleAllergenInput(field, isSpecific, value) {
                if (field === 'allergens' && isSpecific) {
                    this.form.showAllergenInput = value;
                    this.form.additionalAllergen = '';
                }
            },

            checkEditMode() {
                if (this.editMode.isOpened) {
                    const editedData = this.cart.find((item) => item.id === this.editMode.cartItemId);
                    let copiedEditedData = JSON.parse(JSON.stringify(editedData));
                    this.form = copiedEditedData;
                }
            },
        },

        mounted() {
            document.body.classList.add('overflow-hidden');

            this.checkEditMode();
        },

        beforeUnmount() {
            document.body.classList.remove('overflow-hidden');
        },
    };
</script>

<style lang="scss" scoped>
    .wrapper {
        position: fixed;
        z-index: $index-1000;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
    }

    .cart-menu {
        position: absolute;
        bottom: 0;

        z-index: $index-500;

        height: calc(100vh - 110px);

        background: $white;

        border-top-left-radius: $br-20;
        border-top-right-radius: $br-20;

        overflow: hidden;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 0px;
        }

        .header {
            position: sticky;
            top: 0;
            z-index: $index-500;

            @include row-align-center-justify-between;

            padding: 6px 12px;

            background: $primary;

            h3 {
                color: $white;
            }
        }

        .tooltip-text {
            font-style: italic;
            font-size: $font-14;

            color: $grey-static;

            margin-bottom: 14px;
        }

        .radio {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .spin {
            width: 155px;
        }

        // addition__inner
        .addition__inner {
            @include row-align-center-justify-between;

            width: 100%;

            .content {
                color: $primary;

                p {
                    font-size: $font-14;
                    margin-bottom: 10px;
                }
            }

            .img {
                width: 72px;
                height: 72px;

                background-image: url('../../assets/images/backgrounds/default-addition-bg.png');

                box-shadow: $box-shadow-dark;
                border-radius: $br-10;
            }
        }

        // calc-section
        .calc-section {
            .btn {
                width: 100%;
                margin-top: 10px;
            }

            .reset-btn {
                margin-bottom: 30px;
            }

            .total-calc {
                margin-bottom: 20px;

                padding-top: 13px;
                border-top: 1px solid $grey-line;

                h6 {
                    color: $grey-form-label;
                    margin-bottom: 6px;
                }
            }
        }
    }

    @include media($sm) {
        .wrapper {
            background-color: rgba(0, 0, 0, 0.7);
        }

        .cart-menu {
            top: 0;
            right: 0;
            width: 360px;
            height: 100%;

            border-top-left-radius: 0;
            border-top-right-radius: 0;

            .header {
                padding: 15px 20px;
            }
        }
    }
</style>
