<template>
    <div class="footer">
        <div class="footer__inner">
            <router-link to="/">
                <LogoFullSvg class="logo-icon" />
            </router-link>
            <p class="description">
                {{ $t('footer.description') }}
            </p>
            <img src="@/assets/images/es_parama.jpg" alt="es_parama" />
        </div>
        <div class="footer__inner">
            <h4>{{ $t('footer.mainMenu') }}</h4>
            <router-link
                v-for="link in mainMenuLinks"
                :key="link.linkName"
                :class="link.class"
                :to="{ name: link.linkName }"
            >
                {{ link.title }}
            </router-link>
        </div>
        <div class="footer__inner">
            <h4>{{ $t('footer.usefulInformation') }}</h4>
            <router-link
                v-for="link in usefulInformationLinks"
                :key="link.linkName"
                :class="link.class"
                :to="{ name: link.linkName }"
            >
                {{ link.title }}
            </router-link>
        </div>
        <div class="footer__inner">
            <h4>{{ $t('navbar.contacts') }}</h4>
            <a class="footer-item" @click="sendCallGoogleEvent" href="tel:+37064508975">+37064508975</a>
            <a class="footer-item mb-25" href="mailto:info@cityrush.lt">info@cityrush.lt</a>
            <SocialIconsBlock />
        </div>
        <div class="footer__inner">
            <p class="footer-item">
                {{ $t('footer.solution') }} - <a href="https://www.e-lietuva.lt/" target="blank">E-Lietuva</a>
            </p>
            <p class="footer-item">{{ $t('footer.allRightsAreReserved', { year: new Date().getFullYear() }) }}</p>
        </div>
    </div>
</template>

<script>
    import SocialIconsBlock from '@/components/Common/SocialIconsBlock/SocialIconsBlock.vue';
    import LogoFullSvg from '@/assets/icons/navbar/logo-full.svg?inline';
    import { GoogleAnalyticsEnum } from '@/utils/enums';
    import { mapGetters } from 'vuex';

    export default {
        name: 'Footer',
        components: { LogoFullSvg, SocialIconsBlock },
        computed: {
            ...mapGetters('auth', ['user']),
            mainMenuLinks() {
                return [
                    { linkName: 'mealKits', title: this.$t('navbar.mealKits'), class: 'footer-item' },
                    { linkName: 'weeksMenu', title: this.$t('navbar.weeksMenu'), class: 'footer-item' },
                    { linkName: 'prices', title: this.$t('navbar.prices'), class: 'footer-item' },
                    { linkName: 'blogArticles', title: this.$t('navbar.blogArticles'), class: 'footer-item' },
                ];
            },

            usefulInformationLinks() {
                return [
                    { linkName: 'faq', title: this.$t('navbar.faq'), class: 'footer-item' },
                    { linkName: 'contacts', title: this.$t('navbar.contacts'), class: 'footer-item' },
                    {
                        linkName: 'termsAndConditions',
                        title: this.$t('common.termsAndConditions'),
                        class: 'footer-item',
                    },
                    { linkName: 'privacyPolicy', title: this.$t('common.privacyPolicy'), class: 'footer-item' },
                    {
                        linkName: 'dataDeletionPolicy',
                        title: this.$t('dataDeletionPolicy.title'),
                        class: 'footer-item',
                    },
                    { linkName: 'EUSupport', title: this.$t('navbar.eu'), class: 'footer-item' },
                ];
            },
        },
        methods: {
            sendCallGoogleEvent() {
                return this.$gtag.event(GoogleAnalyticsEnum.CALL, {
                    url: window.location.href,
                    customer_info: this.user ?? null,
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .footer {
        background: $primary-500;
        padding: 24px;

        &__inner {
            @include column-align-start;

            margin-bottom: 40px;

            &:last-child {
                margin-bottom: 0;
            }

            .logo-icon {
                width: 130px;
            }

            img {
                margin-top: 20px;
                height: 150px;
            }

            .footer-item {
                font-size: $font-14;
                border-bottom: 1px solid transparent;

                margin-bottom: 16px;

                transition: all 0.3s linear;

                a {
                    font-weight: 700;
                    color: $primary;
                    text-decoration: underline;
                    font-size: $font-14;
                }

                &:hover {
                    border-bottom: 1px solid $black;
                }
            }

            .description {
                max-width: 370px;
                font-style: italic;
                font-size: $font-14;
            }

            h4 {
                margin-bottom: 18px;
            }
        }
    }

    @include media($md) {
        .footer {
            @include row-align-start-justify-between;
            flex-wrap: wrap;
            padding: 40px;

            &__inner {
                &:nth-child(1) {
                    width: 100%;
                }

                &:nth-child(5) {
                    width: 100%;
                }
            }
        }
    }

    @include media($lg) {
        .footer {
            @include row-align-start-justify-start;
            flex-wrap: wrap;

            &__inner {
                h4 {
                    font-size: $font-20;
                }

                .logo-icon {
                    width: 160px;
                    margin-bottom: 10px;
                }

                &:nth-child(1) {
                    width: 40%;
                    margin-right: calc(100% - 40% - 580px);
                }

                &:nth-child(2) {
                    width: 115px;
                }

                &:nth-child(3) {
                    margin-left: 80px;

                    width: 190px;
                }
                &:nth-child(4) {
                    margin-left: 80px;
                    width: 115px;
                }
            }
        }
    }
</style>
