<template>
    <div class="calc-data" :class="classes">
        <h5>{{ title }}</h5>
        <h5>
            <span v-if="isDiscount">-</span>
            €{{ price }}
        </h5>
    </div>
</template>

<script>
    export default {
        name: 'CalcSection',
        props: {
            title: {
                type: String,
                default: '',
            },
            price: {
                type: String,
                default: '',
            },
            isReverse: {
                type: Boolean,
                default: false,
            },
            isSecondary: {
                type: Boolean,
                default: false,
            },
            isDiscount: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            classes() {
                return [this.isReverse && `reverse`, this.isSecondary && `secondary`];
            },
        },
    };
</script>

<style lang="scss" scoped>
    .calc-data {
        @include row-align-center-justify-between;
        margin-bottom: 8px;

        &.secondary {
            color: $grey-form-label;
        }

        &.reverse {
            flex-direction: row-reverse;
        }
    }
</style>
