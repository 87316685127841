<template>
    <div class="default-container">
        <Navbar />
        <router-view class="default-container__inner" />
        <CartSideMenu v-if="isOpened" :meal-kit="selectedMealKit" />
        <Footer v-if="!isFooterHidden" />
    </div>
</template>

<script>
    import Footer from '@/components/Common/Footer/Footer.vue';
    import Navbar from '@/components/Common/Navbar/Navbar.vue';
    import CartSideMenu from '@/components/Cart/CartSideMenu.vue';
    import { mapGetters } from 'vuex';

    export default {
        name: 'DefaultLayout',
        components: {
            Navbar,
            CartSideMenu,
            Footer,
        },
        computed: {
            ...mapGetters('cart', ['isOpened', 'selectedMealKit']),

            isFooterHidden() {
                return (
                    this.$route.name === 'faq' ||
                    this.$route.name === 'checkOutDelivery' ||
                    this.$route.name === 'checkOutBuyerInfo' ||
                    this.$route.name === 'checkOutPayment' ||
                    this.$route.name === 'individualOrderKit' ||
                    this.$route.name === 'individualOrderDelivery' ||
                    this.$route.name === 'individualOrderBuyerInfo' ||
                    this.$route.name === 'individualOrderSubmit' ||
                    this.$route.name === 'individualOrderPayment'
                );
            },
        },
    };
</script>

<style lang="scss" scoped>
    .default-container {
        min-height: 100vh;

        padding-top: 75px;

        &__inner {
            min-height: calc(100vh - 75px);
            padding: 0 12px;

            @include media($lg) {
                padding: 0 55px;
            }

            @include media($xxl) {
                padding: 0 155px;
            }
        }
    }
</style>
